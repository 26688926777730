// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$tull-portal-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($tull-portal-theme);
  /*   color variables */
  --clr-primary: #5930ff;
  --clr-primary-hover: #29e6a7;
  --clr-primary-dark: #039d69;
  --clr-accent: #6b1d92;
  --clr-gray100: #f9fbff;
  --clr-gray150: #f4f6fb;
  --clr-gray200: #eef1f6;
  --clr-gray300: #e1e5ee;
  --clr-gray400: #767b91;
  --clr-gray500: #4f546c;
  --b3f: 250,250,250;
  --i1d: 38,38,38;
  --edc: 199,199,199;
  --f75: 38,38,38;
  --fe0: 0,55,107;
  --d87: 255,255,255;
  --b3f: 250,250,250;
  --bb2: 239,239,239;
  --f23: 255,255,255;
  --b38: 219,219,219;
  --b6a: 219,219,219;
  --ca6: 219,219,219;
  --cdd: 38,38,38;
  --e22: 199,199,199;
  --e62: 245,251,255;
  --b2f: 88,195,34;
  --c8c: 168,168,168;
  --ce3: 239,239,239;
  --jd9: 0,0,0;
  --j64: 54,54,54;
  --a97: 243,243,243;
  --d20: 38,38,38;
  --f52: 142,142,142;
  --h1d: 255,255,255;
  --de5: 255,255,255;
  --d69: 0,149,246;
  --b86: 88,195,34;
  --i30: 237,73,86;
  --d62: 255,255,255;
  --e4f: 54,54,54;
  --a72: 255,255,255;
  --ie3: 142,142,142;
  --c37: 237,73,86;
  --f24: 0,149,246;
  --daf: 255,1,105;
  --f76: 28,209,79;
  --jbb: 53,121,234;
  --aab: 118,56,250;
  --eca: 255,255,255;
  --jb7: 0,0,0;
  --fa7: 224,241,255;
  --aa6: 38,38,38;
  --ba8: 168,168,168;
  --eac: 237,73,86;

  /*   border radius */
  --radius: 0.2rem;
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($tull-portal-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($tull-portal-theme);
/* You can add global styles to this file, and also import other style files */

html, body {
  box-sizing: border-box;
  height: 100%;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.inner-col {
  align-items: stretch;
  flex-grow: 1;
}

.rows {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.padded-app-content {
  padding: 16px 32px;
}

main, article {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.flex-row {
    display: flex;
    flex: 1 1 0;
}

.flex-row .col {
    flex-grow: 1;
    flex-basis: 0;
    align-items: baseline;
}

.data-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;

    &-xs {
      max-width: 120px;
    }

    &-sm {
      max-width: 320px;
    }

    &-md {
      max-width: 480px;
    }

    &-lg {
      max-width: 720px;
    }

    &-xl {
      max-width: 960px;
    }

    &-full-width, &-fw {
      max-width: 100%;
    }

    & mat-form-field {
      width: 100%;
    }

    & .field-row {
      width: 100%;
      margin-bottom: 20px;
    }

    & .form-action {
      margin-top: 1rem;
    }

    & .form-action-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-section-header {
      box-sizing: border-box;
      display: flex;
      padding: 0.5em;
      align-items: center;
      height: 48px;
      background-color: rgb(142, 142, 147);
      margin-bottom: 0.5em;

      & h4 {
        margin: 0;
      }
    }

    & .action-panel {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    & .data-form-toolbar {
      & .action-panel {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        & button {
          background: rgb(216, 216, 220);
        }
      }
    }

    & .multi-field {
      & .multi-field-row {
        box-sizing: border-box;
        display: flex;
        margin-bottom: 0.5em;

        &-vcenter {
          align-items: center;
        }

        & .multi-field-row-item {
          box-sizing: border-box;
          flex-grow: 1;

          &-shrink {
            flex-grow: 0;
            flex-shrink: 1;
          }
        }

        & .multi-field-row-action {
          box-sizing: border-box;
          width: 48px;
        }
      }
    }
}

.full-width-form-control {
  width: 100%;
}

.pad-right {
    padding-right: 12px;
}

.mastermode {
    background-color: #f54000;
    font-weight: bold;
}

span.mastermode {
    padding: 0 0.25em;
}

.fab-action {
    position: absolute !important;
    bottom: 16px;
    right: 33px;
}

.action-panel a {
    text-decoration: none;
    margin-left: 0.5em;
}

.sub-option {
    text-align: center;
    cursor: pointer;
}

    .sub-option a {
        color: #FFF;
        text-decoration: none;
    }

.subsection-active {
    font-weight: bold;
}

.table-action-panel {
    text-align: center;
}

.table-center {
    text-align: center;
}

.pl-list-item {
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;
    justify-content: center;
    flex-basis: 0;
    padding: 0.5em;

    &-plain {
        background-color: whitesmoke !important;
        border-radius: 8px !important;
    }

    &>div.pl-item-form {
      flex-grow: 1;
    }

    &>div.action-panel {
        flex-grow: 0;
        align-self: center !important;
        height: 24px;
    }
}

.col-grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .25em;
}

.two-column {
  box-sizing: border-box;
  display: flex;
  gap: 1em;

  & .column {
    box-sizing: border-box;
    flex-grow: 1;
    align-self: flex-start;

    &-shrink {
      flex-grow: 0;
      flex-shrink: 1;
    }
  }
}

.hyp-list {
  & .hyp-list-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid #CCC;
    margin-bottom: 1em;

    & .hyp-list-row {
      border: 0;
      margin-bottom: 0;
    }

    & .row-hidden {
      display: none;
      max-height: 0;
      transition: max-height 1s ease-out;
    }

    & .row-active {
      max-height: 700px !important;
      transition: max-height 1.25s ease-in;
    }
  }

  & .hyp-list-row {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 1em;
    width: 100%;
    border: 1px solid #CCC;
    margin-bottom: 1em;
    background-color: #FFFFFF;

    & .hyp-list-header {
      color: #c5cfd5;
      background-color: #f2f5f7;
    }

    & .hyp-list-item {
      box-sizing: border-box;
      color: #738490;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      display: inline-block;
      min-height: 40px;
      line-height: 40px;
    }

    & h4 {
      margin: 0;
      padding: 0;
    }

    & .full-panel {
      flex-grow: 1 !important;
      & .panel {
        justify-content: flex-end !important;
      }
    }

    & .hyp-action {
      min-width: 40px;
      min-height: 40px;
      flex-grow: 0;

      & .panel {
        display: flex;
        align-items: center;
      }

      & .action {
        flex-grow: 0 !important;
        flex-shrink: 1 !important;

        & button {
          display: block;
        }
      }
    }
  }
}

.app-section-header{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 1rem;
  width: 100%;

  & .app-category-container {
    border-bottom: 2px solid #EcF1F3;
  }

  & .app-section-header-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    & .app-section-header-category {
      box-sizing: border-box;
      padding: 0.5em;
      text-align: center;
      height: 32px;

      & a, & span {
        text-decoration: none;
        color: #6E8898;
        cursor: pointer;
      }

      &.active {
        border-bottom: 4px solid #AAA;

        & a {
          color: rgb(108, 108, 112);
          font-weight: bold;
        }
      }
    }
  }
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  table-layout: fixed;
  box-sizing: border-box;

  & .data-table {
    border-bottom: 0;

    & tr:first-child,
    & tr:last-child {
      border-bottom: 0;
    }

    & tr:nth-child(2) {
      border-top: 1px solid #000;
    }

    & td:last-child {
      border-right: 0;
    }
  }

  & tr {
    border-bottom: 1px solid #000;
  }

  & td, th {
    box-sizing: border-box;
    border-right: 1px solid #000;
  }

  & td {
    padding: 0.25em;

    & input {
      display: inline-block;
      width: 80%;
    }

    & textarea {
      width: 90%;
    }
  }

  & .table-center-content {
    text-align: center;
  }
}

.manager-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  margin-bottom: 1rem;
  gap: 1em;
}

.alert {
  box-sizing: border-box;
  padding: 1em;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  opacity: 1.0;
  transition: opacity 2.5s ease-out;
  visibility: visible;

  & button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.hide {
  border: 0;
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.fade-out {
  opacity: 0;
}

.alert-danger {
  color: #a94442;
  background-color: #F2DEDE;
  border-color: #EBCCD1;
}

.alert-success {
  color: #3C763d;
  background: #dff0d8;
  border-color: #d6e9c6;
}

.number-circle {
  display: flex;
  width: fit-content;
  min-width: 1rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid #666;
}

.action-panel-container {
  width: 60px;
}

.filter-panel {
  display: flex;
  align-items: flex-start;
  gap: 1em;
}

.error {
  color: rgb(255,0,51);
}

.warn {
  color: rgb(219,84,0);
  font-weight: bold;
}

.info {
  color: rgb(51,102,204);
}

.xsmall-spinner {
  width: 25px;
  height: 25px;
}

.section-header {
  font-size: 1.5em;
  background: rgb(142, 142, 147);
  padding: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;

  & h4 {
    flex-grow: 1;
    margin: 0;
  }

  & button,
  & a {
    background: #DDD;
    margin-right: 0.25em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.standard-editor-grid-container {
  padding-right: 16px;
  height: 100%;
  overflow-y: scroll;

  & .standard-editor-grid {
    display: grid;
    grid-template-columns: minmax(200px, 400px) 1fr;
    gap: 1em;

    & .sticky-column {
      position: sticky;
      top: 0;
      align-self: start;
    }
  }

}
